<template>
    <div>
        <b-card>
            <b-row>
                <b-col lg="12">
                    <UserSurveys :user-id="$route.params.id"/>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>

    import {BCard, BCol, BRow} from 'bootstrap-vue'
    // import UserSales from '@/views/User/UserSale'
    // import UserSalesLoaction from '@/views/User/UserLocations'
    import UserSurveys from '@/views/User/UserSurveys'


    export default {
        components: {
            BCard,
            BRow,
            BCol,
            // BButton,
            UserSurveys
            // UserSales
            // UserSalesLoaction
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>