<template>
    <div>
        <b-overlay>
            <b-card title="Surveys">
                <b-row class="mb-2">
                    <b-col sm="4">
                        <label>Date from</label>
                        <b-form-datepicker v-model="dateFrom" value-as-date/>
                    </b-col>
                    <b-col sm="4">
                        <label>Date to</label>
                        <b-form-datepicker v-model="dateTo" value-as-date/>
                    </b-col>
                </b-row>
                <SurveyResults :data-url="`/api/management/v1/survey/results/${  this.userId}`" :showUser="showUser" :showDelete="showDelete" :addDateToUrl="addDateToUrl" :dateFrom="dateFrom" :dateTo="dateTo"/>

                <survey-outlet-locations :user-id="userId" :dateFromLocation="dateFrom" :dateToLocation="dateTo"></survey-outlet-locations>
            </b-card>
        </b-overlay>
    </div>
</template>
<script>

    import {BCard, BOverlay, BRow, BCol, BFormDatepicker} from 'bootstrap-vue'
    import SurveyResults from '@/views/components/SurveyResults'
    import SurveyOutletLocations from '../Survey/SurveyOutletLocations.vue'

    export default {
        components: {
            BCard,
            BOverlay,
            BRow,
            BCol,
            BFormDatepicker,
            SurveyResults,
            SurveyOutletLocations
        },
        props: {
            userId: {
                type: String,
                required:true
            }
        },
        data() {
            return {
                addDateToUrl: true,
                showUser: false,
                showDelete: false,
                dateFrom: null,
                dateTo: null,
                surveyDataForUser: null
            }
        },
        methods: {
            setDate() {
                this.dateFrom = this.moment().startOf('month').toDate()
                this.dateTo = this.moment(new Date()).toDate()
            }
        },
        mounted() {
            this.setDate()
        }
    }
</script>