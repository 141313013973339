<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Locations">
                <b-row>
                    <b-col>
                        <b-form-checkbox v-model="clusterLocations">Cluster locations</b-form-checkbox>
                    </b-col>
                </b-row>
                <hr/>
                <div id="map_1" style="width: 100%; height: 500px"></div>
            </b-card>
        </b-overlay>
    </div>
</template>
<script>

    import {BCard, BOverlay, BFormCheckbox, BRow, BCol} from 'bootstrap-vue'
    import mapboxgl from 'mapbox-gl'
    import 'mapbox-gl/dist/mapbox-gl.css'

    export default {
        components: {
            BCard,
            BOverlay,
            BFormCheckbox,
            BRow,
            BCol
        },
        props: {
            userId: {
                type: String,
                required:true
            },
            dateFromLocation:{
                type: Date
            },
            dateToLocation:{
                type: Date
            }
        },
        data() {
            return {
                dataLoaded: false,
                locations: [],
                displayConfig: {
                    locations: {
                        display: true,
                        color: 0,
                        selected_locations: [],
                        selected_ids: []
                    }
                },
                displayedMapData: {
                    locations: [],
                    outlinesEnabled: true
                },
                mapData: null,
                map: null,
                clusterLocations: true
            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                if (!this.dateFromLocation || !this.dateToLocation) {
                    this.dataLoaded = true
                    return
                }
                const dateFromLocationTmp = this.moment(this.dateFromLocation).startOf('day').format('YYYY-MM-DD')
                const dateToLocationTmp = this.moment(this.dateToLocation).endOf('day').format('YYYY-MM-DD')

                const outletLoadPromise = this.$http.get(`/api/management/v1/survey/results/location/${this.userId}?date_from=${dateFromLocationTmp}&date_to=${dateToLocationTmp}`)
                outletLoadPromise.then(function(response) {
                    thisIns.locations = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([outletLoadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                    thisIns.loadMapData()
                })
            },
            loadMapData() {
                this.mapData = {
                    locations: this.getLocationsMapDisplayData()
                }
                this.removeDisplayedMapData()
                this.setLocationsSource(this.mapData.locations)
            },
            addLocationsSource() {
                this.map.addSource('locations', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: []
                    },
                    cluster: true,
                    clusterMaxZoom: 14, // Max zoom to cluster points on
                    clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
                })

                this.map.addSource('locations-unclustered', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: []
                    }
                })
            },
            setLocationsSource(locations) {
                
                const locationsGeoJson = {
                    type: 'FeatureCollection',
                    features: locations.map(outlet => {
                        return {
                            type: 'Feature',
                            properties: {
                                popup_data: outlet.popup_data,
                                color: outlet.color
                            },
                            geometry: {
                                type: 'Point',
                                coordinates: outlet.location
                            }
                        }
                    })
                }

                this.map.getSource('locations').setData(locationsGeoJson)
                this.map.getSource('locations-unclustered').setData(locationsGeoJson)
            },
            addLocationsDisplayLayers() {


                this.map.addLayer({
                    id: 'clusters',
                    type: 'circle',
                    source: 'locations',
                    filter: ['has', 'point_count'],
                    paint: {
                        'circle-color': [
                            'step',
                            ['get', 'point_count'],
                            '#2ecc71',
                            100,
                            '#f1c40f',
                            300,
                            '#f39c12',
                            500,
                            '#e67e22',
                            750,
                            '#d35400',
                            1500,
                            '#e74c3c',
                            3000,
                            '#c0392b'
                        ],
                        'circle-radius': [
                            'step',
                            ['get', 'point_count'],
                            20,
                            100,
                            25,
                            300,
                            30,
                            500,
                            35,
                            750,
                            40,
                            1500,
                            45,
                            3000,
                            50
                        ]
                    }
                })

                this.map.addLayer({
                    id: 'cluster-count',
                    type: 'symbol',
                    source: 'locations',
                    filter: ['has', 'point_count'],
                    layout: {
                        'text-field': '{point_count_abbreviated}',
                        'text-size': 12
                    }
                })

                this.map.on('click', 'clusters', (e) => {
                    const features = this.map.queryRenderedFeatures(e.point, {
                        layers: ['clusters']
                    })
                    const clusterId = features[0].properties.cluster_id
                    this.map.getSource('locations').getClusterExpansionZoom(
                        clusterId,
                        (err, zoom) => {
                            if (err) return

                            this.map.easeTo({
                                center: features[0].geometry.coordinates,
                                zoom
                            })
                        }
                    )
                })

                this.map.addLayer({
                    id: 'outlet-point',
                    type: 'circle',
                    source: 'locations',
                    filter: ['!', ['has', 'point_count']],
                    paint: {
                        'circle-color': ['get', 'color'],
                        'circle-radius': 8,
                        'circle-stroke-width': 1,
                        'circle-stroke-color': '#fff'
                    }
                })

                this.map.addLayer({
                    id: 'outlet-point-unclustered',
                    type: 'circle',
                    source: 'locations-unclustered',
                    layout: {
                        visibility: 'none'
                    },
                    paint: {
                        'circle-color': ['get', 'color'],
                        'circle-radius': 8,
                        'circle-stroke-width': 1,
                        'circle-stroke-color': '#fff'
                    }
                })

                this.map.on('click', 'outlet-point', (e) => {
                    const coordinates = e.features[0].geometry.coordinates.slice()

                    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
                    }

                    new mapboxgl.Popup()
                        .setLngLat(coordinates)
                        .setHTML(
                            `${e.features[0].properties.popup_data}`
                        )
                        .addTo(this.map)
                })

                this.map.on('click', 'outlet-point-unclustered', (e) => {
                    const coordinates = e.features[0].geometry.coordinates.slice()

                    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
                    }

                    new mapboxgl.Popup()
                        .setLngLat(coordinates)
                        .setHTML(
                            `${e.features[0].properties.popup_data}`
                        )
                        .addTo(this.map)
                })

                this.map.on('mouseenter', 'clusters', () => {
                    this.map.getCanvas().style.cursor = 'pointer'
                })
                this.map.on('mouseleave', 'clusters', () => {
                    this.map.getCanvas().style.cursor = ''
                })

            },
            addAreaLayerToMap(area) {

                this.map.addSource(area.id, {
                    'type': 'geojson',
                    'data': {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Polygon',
                            'coordinates': [area.polygon]
                        }
                    }
                })

                this.map.addLayer({
                    'id': `${area.id  }_fill`,
                    'type': 'fill',
                    'source': area.id,
                    'layout': {},
                    'paint': {
                        'fill-color': (area.color.length > 0) ? area.color : '#000',
                        'fill-opacity': (area.color.length > 0) ? 0.5 : 0
                    }
                })

                if (this.displayedMapData.outlinesEnabled) {
                    this.map.addLayer({
                        'id': `${area.id  }_outline`,
                        'type': 'line',
                        'source': area.id,
                        'layout': {},
                        'paint': {
                            'line-color': '#000',
                            'line-width': 1,
                            'line-opacity': (area.color.length > 0) ? 1 : 0
                        }
                    })
                }
            },
            removeDisplayedMapData() {
                this.displayedMapData.locations = []
            },
            getLocationsMapDisplayData() {
                return this.locations.map((location, id) => {
                    return {
                        id: `${location.id  }_${  id}`,
                        location: location.location,
                        color: '#3498db',
                        popup_data: `<b>${location.outlet}: ${location.count}</b>`
                    }
                })
            },
            attachMap() {
                const thisIns = this
                return new Promise(function(resolve) {
                    mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN

                    thisIns.map = new mapboxgl.Map({
                        container: 'map_1',
                        style: 'mapbox://styles/mapbox/streets-v11',
                        center: thisIns.$store.state.app.default_map_view.center,
                        zoom: thisIns.$store.state.app.default_map_view.zoom
                    })

                    thisIns.map.on('style.load', function() {
                        resolve()
                    })
                })
            }
        },
        watch: {
            displayConfig: {
                handler() {
                    this.loadMapData()
                },
                deep: true
            },
            dateFromLocation() {
                this.loadData()
            },
            dateToLocation() {
                this.loadData()
            },
            clusterLocations(val) {
                if (val) {
                    this.map.setLayoutProperty('outlet-point-unclustered', 'visibility', 'none')

                    this.map.setLayoutProperty('outlet-point', 'visibility', 'visible')
                    this.map.setLayoutProperty('cluster-count', 'visibility', 'visible')
                    this.map.setLayoutProperty('clusters', 'visibility', 'visible')
                } else {
                    this.map.setLayoutProperty('outlet-point-unclustered', 'visibility', 'visible')

                    this.map.setLayoutProperty('outlet-point', 'visibility', 'none')
                    this.map.setLayoutProperty('cluster-count', 'visibility', 'none')
                    this.map.setLayoutProperty('clusters', 'visibility', 'none')
                }
            }
        },
        mounted() {
            const thisIns = this
            const mapLoadPromise = this.attachMap()
            mapLoadPromise.finally(function() {
                thisIns.addLocationsSource()
                thisIns.addLocationsDisplayLayers()
                thisIns.loadData()
            })
        }
    }
</script>